<template>
  <div class="text-center lg:mx-32 my-32 text-gray-700 content">
    <template v-if="result.price">
      <p>
        Vous pouvez souscrire une assurance Responsabilité du dirigeant à partir
        de :
      </p>

      <price-result :price="result.price" class="py-8"></price-result>

      <p class="mb-6">
        Un conseiller Groupama prendra contact avec vous pour établir un devis
        personnalisé très rapidement.
      </p>
    </template>
    <template v-else>
      <p class="mb-6">
        Votre situation nécessite une étude approfondie. Un conseiller prendra
        contact avec vous pour vous apportez un tarif.
      </p>
    </template>

    <p class="mb-6">
      Pour toutes précisions complémentaires et pour établir un devis
      d'assurance personnalisé, nous sommes à votre disposition au
      <strong>{{ result.contactPhone }}</strong> (coût d'un appel local ou
      gratuit selon votre abonnement)<br />
      {{ result.contactOpeningHours }}<br />
      ou par mail :
      <a :href="`mailto:${result.contactEmail}`">{{ result.contactEmail }}</a>
    </p>

    <p class="text-xs" v-if="result.price">
      (1) Offre soumise à conditions. Tarif indicatif TTC valable jusqu'au
      31/12/2024 (en mode de paiement annuel) pour la souscription d'un contrat
      Responsabilité des Dirigeants avec une limites de garantie de
      {{ $formatNumber(result.warrantyCeiling) }} €. Conditions détaillées en
      demandant un devis d'assurance personnalisé à un conseiller Groupama.
    </p>
  </div>
</template>

<script>
import PriceResult from "@/components/base/PriceResult.vue"

export default {
  components: { PriceResult },
  props: {
    result: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
