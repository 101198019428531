import Vue from "vue";
import App from "./App.vue";
import "./plugins/http";
import "./plugins/rdd-tracking";
import "./plugins/vee-validate";
import "./assets/tailwind.css";
import vClickOutside from "v-click-outside";
import RddForm from "./components/forms/RddForm.vue";
import { formatNumber } from "./plugins/utils";

Vue.use(vClickOutside);
Vue.component("MainForm", RddForm);
Vue.prototype.$formatNumber = formatNumber;

Vue.config.productionTip = false;

/**
 * Vue mount
 */
new Vue({
  render: (h) => h(App),
}).$mount("#app");
