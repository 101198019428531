import Vue from "vue";
import { encrypt } from "./crypto";
import { getRange } from "./utils";

/**
 * Get target from query string
 */
let targets = {
  ACPS: "Entreprise de - 10 salariés",
  ENT: "Entreprise de 10 salariés ou +",
  AGRI: "Exploitation agricole",
};

let targetTrackingLabels = {
  ACPS: "Entreprise",
  ENT: "Entreprise",
  AGRI: "Agriculteur",
};

const urlParams = new URLSearchParams(window.location.search);
let targetCode = urlParams.get("target");

if (targetCode && !targets[targetCode]) {
  throw new Error("Type de cible non pris en charge");
}

let targetLabel = targets[targetCode];

let rdd = {
  /**
   * Code et libellé cible
   */
  targetCode,
  targetLabel,
  targets,
  targetsAsOptions: Object.entries(targets).map(([value, text]) => {
    return { value, text };
  }),
  getRange,
  /**
   * Push DTM tracking
   */
  push({ step, title, type, postCode, data, common }) {
    window.wa_gfr = {
      contenu: {
        plateforme: "Useweb",
        version: "1.0.0",
        region_enregistree: "LBR",
        type_content: "Devis",
        marche: targetTrackingLabels[type],
        fil_ariane: `Mon assurance::Devis RDD LBR ${step} ${title}`,
        code_postal: postCode,
      },
      contact: {
        origine: "site marchand",
      },
      outils_an: {
        product_range: "RDD",
        identifiant_produit: "RDD",
        nom_etape: title,
        numero_etape: step,
      },
      devis: {
        rdd: data,
      },
      commun: common
        ? {
            hash_email: encrypt(common.email),
            hash_phone: encrypt(common.phone),
          }
        : {},
    };

    if (window._satellite && window._satellite.track) {
      window._satellite.track(`devis_rdd_e${step}`);
    }
  },
};

Vue.prototype.$rdd = rdd;
