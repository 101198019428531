<template>
  <div>
    <p class="text-green-500 mb-12">Vos coordonnées :</p>

    <div class="form-columns-layer">
      <input-wrapper
        label="Vous êtes ?"
        required
        rules="required"
        v-if="!isTargeted"
      >
        <choices-input
          v-model="form.type"
          :options="$rdd.targetsAsOptions"
        ></choices-input>
      </input-wrapper>

      <div class="columns">
        <div class="column">
          <input-wrapper
            label-for="companyName"
            :label="companyNameLabel"
            required
            rules="required"
          >
            <input
              v-model="form.companyName"
              id="companyName"
              class="form-control"
            />
          </input-wrapper>
        </div>
        <div class="column">
          <input-wrapper
            label-for="name"
            label="Nom prénom"
            required
            rules="required"
          >
            <input v-model="form.name" id="name" class="form-control" />
          </input-wrapper>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <input-wrapper
            label-for="email"
            label="Email"
            required
            rules="required|email"
          >
            <input
              v-model="form.email"
              type="email"
              id="email"
              class="form-control"
            />
          </input-wrapper>
        </div>
        <div class="column">
          <input-wrapper
            label-for="phone"
            label="Téléphone"
            required
            rules="required|phone|min:10|max:10"
          >
            <input
              v-model="form.phone"
              type="tel"
              id="phone"
              class="form-control"
            />
          </input-wrapper>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <input-wrapper
            label-for="street"
            label="Adresse"
            required
            rules="required"
          >
            <input v-model="form.street" id="street" class="form-control" />
          </input-wrapper>
        </div>
        <div class="column">
          <input-wrapper
            label-for="city"
            label="Ville (CP)"
            required
            rules="required"
          >
            <place-input v-model="form.city" id="city" />
          </input-wrapper>
        </div>
      </div>
      <input-wrapper
        label-for="turnover"
        label="Chiffre d'affaires"
        required
        rules="required|numeric"
        class="input-currency"
      >
        <number-input v-model="form.turnover" id="turnover"></number-input>
      </input-wrapper>
    </div>

    <div class="flex">
      <span class="block ml-auto">(*) champs obligatoires</span>
    </div>

    <slot name="actions"></slot>

    <div class="mt-5 text-center">
      <captcha-info></captcha-info>
    </div>
  </div>
</template>

<script>
import Step from "@/mixins/wizard-step";
import InputWrapper from "@/components/base/InputWrapper.vue";
import PlaceInput from "@/components/base/PlaceInput.vue";
import CaptchaInfo from "@/components/base/CaptchaInfo.vue";
import NumberInput from "@/components/base/NumberInput.vue";
import ChoicesInput from "@/components/base/ChoicesInput.vue";

export default {
  mixins: [Step],
  props: {
    type: String,
  },
  components: {
    PlaceInput,
    InputWrapper,
    CaptchaInfo,
    NumberInput,
    ChoicesInput,
  },
  data: () => ({
    form: {
      type: "",
      companyName: "",
      name: "",
      email: "",
      phone: "",
      street: "",
      city: "",
      turnover: null,
    },
  }),
  computed: {
    companyNameLabel() {
      if (this.type === "AGRI") {
        return "Nom de l'exploitation";
      }
      return "Nom de l'entreprise";
    },
    isTargeted() {
      return !!this.$rdd.targetCode;
    },
  },
  created() {
    /**
     * Préselection du type depuis l'URL
     */
    if (this.isTargeted) {
      this.form.type = this.$rdd.targetCode;
    }
  },
};
</script>

<style lang="postcss" scoped>
.form-columns-layer {
  /deep/ .form-group {
    @screen lg {
      label {
        @apply absolute w-44 text-right;
      }

      > div {
        @apply ml-48;
      }
    }
  }
}
</style>
