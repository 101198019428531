<template>
  <ValidationObserver v-slot="{ invalid, failed }">
    <steps
      :steps="steps"
      :value="currentStep"
      @move-step="(i) => (i < currentStep || !invalid) && moveToStep(i)"
      class="hidden sm:flex mb-8"
    ></steps>

    <form @submit.prevent="onSubmit">
      <transition name="slide-fade" mode="out-in">
        <component
          :is="`step${currentStep}`"
          v-model="currentForm"
          :type="form[0].type"
          :result="result"
        >
          <div slot="actions" class="flex flex-col mt-5" v-if="!isEnd">
            <span
              v-if="errorMessage || failed"
              class="text-red-500 ml-auto mb-5 font-bold lg:w-1/3 text-right"
              >{{ errorMessage || "Merci de vérifier votre saisie." }}</span
            >
            <div class="flex">
              <button
                v-if="canPrev"
                type="button"
                class="btn-orange"
                @click="goPrev"
              >
                Précédent
              </button>
              <button
                v-if="canNext"
                class="btn-orange ml-auto"
                type="submit"
                :disabled="invalid || pending"
              >
                <loading-icon
                  v-if="pending"
                  class="animate-spin h-5 w-5"
                ></loading-icon>
                <span v-else>Suivant</span>
              </button>
            </div>
          </div>
        </component>
      </transition>
    </form>
  </ValidationObserver>
</template>

<script>
import WizardForm from "@/mixins/wizard-form";
import Steps from "@/components/base/Steps.vue";
import LoadingIcon from "@/assets/icons/loading.svg";

import Step1 from "@/components/forms/rdd/steps/Step1.vue";
import Step2 from "@/components/forms/rdd/steps/Step2.vue";

export default {
  name: "Form",
  mixins: [WizardForm],
  components: {
    Steps,
    Step1,
    Step2,
    LoadingIcon,
  },
  data: () => ({
    steps: [
      { title: "Vos informations", icon: "email" },
      { title: "Votre tarif", icon: "euro" },
    ],
    form: [
      {
        type: "",
        companyName: "",
        name: "",
        email: "",
        phone: "",
        street: "",
        city: "",
        turnover: null,
      },
    ],
  }),
  mounted() {
    this.$rdd.push({ step: 1, title: "Vos informations" });
  },
  methods: {
    getStep1Data() {
      return {
        type_client: this.form[0].type,
        nom_entreprise: this.form[0].companyName,
        ca: this.$rdd.getRange(
          this.form[0].turnover,
          [100000, 250000, 500000, 1500000, 7500000],
          (v) => `${v}€`
        ),
      };
    },
    getPersonalData() {
      return {
        email: this.form[0].email,
        phone: this.form[0].phone,
      };
    },
    async onSubmit() {
      if (this.pending) {
        return;
      }
      this.pending = true;
      let data = {};

      switch (this.currentStep) {
        case 1:
          /**
           * Validation serveur et récupération du tarif
           */
          data = {
            ...Object.assign({}, ...this.form),
          };

          if (window.grecaptcha) {
            data.captchaResponse = await window.grecaptcha.execute(
              "6Lf45NYZAAAAAEHKOUbU-GDaw5b-jMSgvUHKKd9e",
              {
                action: "submit",
              }
            );
          }

          try {
            this.result = await this.$http("POST", "email/rdd", data);

            /**
             * Tracking après validation
             */
            this.$rdd.push({
              step: 2,
              title: "Votre tarif",
              type: this.form[0].type,
              postCode: this.form[0].city.slice(0, 5),
              data: this.getStep1Data(),
              common: this.getPersonalData(),
            });

            this.goNext();
          } catch (e) {
            this.errorMessage = e.message;
          } finally {
            this.pending = false;
          }
          break;
      }
    },
  },
};
</script>
